import Swal from "sweetalert2";

// Genel confirmDialog
export function confirmDialog({
                                  title = "Emin misiniz?",
                                  message = "Bu işlemi geri alamazsınız.",
                                  icon = "question",
                                  confirmText = "Evet",
                                  cancelText = "Vazgeç",
                                  confirmButtonColor = "#e31748"
                              }) {
    return Swal.fire({
        title,
        text: message,
        icon,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        confirmButtonColor
    }).then((result) => result.isConfirmed);
}

export function confirmDialogDelete({context, message}) {
    return confirmDialog({
        title: context.$t('general.deletion_confirmation'),
        message: message || context.$t('general.sure_to_delete'),
        icon: "warning",
        confirmText: context.$t('general.yes_delete_it'),
        cancelText: context.$t('general.cancel'),
        confirmButtonColor: "#e31748"
    });
}
